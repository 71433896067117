/* The following block can be included in a custom.scss */

/* make the customizations */
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

@import "variables";

$theme-colors: (
  "primary": $primary,
  "primary-mono": $primary-mono,
  "primary-dark":$primary-dark,
  "secondary": #383b4d,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
  "info": $info,
  "dark": #26272e,
  "dark-form": #2d2e36,
  "dark-light": #373943,
);

$custom-colors: (
  "grey-100": $grey-100,
  "grey-200": $grey-200,
  "grey-300": $grey-300,
  "grey-400": $grey-400,
  "grey-500": $grey-500,
  "grey-600": $grey-600,
  "grey-700": $grey-700,
  "grey-800": $grey-800,
  "grey-900": $grey-900,

  "primary-100": $primary-100,
  "primary-200": $primary-200,
  "primary-300": $primary-300,
  "primary-400": $primary-400,
  "primary-500": $primary-500,


  "danger-100": $danger-100,
  "danger-200": $danger-200,
  "danger-300": $danger-300,
  "danger-400": $danger-400,
  "danger-500": $danger-500,

  
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$spacer: 1rem;
$spacers: (
  1: ($spacer * .25),
  2: $spacer * .5,
  3: ($spacer),
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4),
  8: ($spacer * 6),
  9: ($spacer * 8),
  10: ($spacer * 10),
);

$font-sizes:(
  1: 10rem,
  2: 8rem,
  3: 6rem,
  4: 4rem,
  5: 3rem,
  6: 2.25rem,
  7: 1.875rem,
  8: 1.5625rem,
  9: 1.25rem,
  10: 1.125rem,
  11: 1.025rem,
  12: 1rem,
  13: .9rem,
  14: .8rem, 
  15: .7rem,
  16: .6rem,
  17: .5rem,
  18: .4rem
);

$grid-breakpoints: (
  xxs: 0,
  xs: 479px,
  sm: 620px,
  md: 789px,
  lg: 960px,
  xl: 1149px,
  xxl: 1400px
);

$xxs: map-get($grid-breakpoints, "xxs");
$xs: map-get($grid-breakpoints, "xs");
$sm: map-get($grid-breakpoints, "sm");
$md: map-get($grid-breakpoints, "md");
$lg: map-get($grid-breakpoints, "lg");
$xl: map-get($grid-breakpoints, "xl");
$xxl: map-get($grid-breakpoints, "xxl");


@import "~bootstrap/scss/bootstrap";
