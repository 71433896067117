.aboutus-wrapper{
    display: grid;
    grid-template-columns: 500px 1fr;
    .aboutus-image{
    
        width: 350px;
        height:350px;

    
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        // background-attachment: fixed;
    
        // clip-path: polygon(50px 0%, 100% 0%, 100% 100%, 0% 100%);
        // filter: brightness(.5)
    }
}
