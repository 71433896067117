.form-row{
    .input-container{
        position:relative;
        .icon{
            position: absolute;
            top:11px;
            left:12px;
        }
        input{
            padding-left: 44px;
            padding-top: 10px;
            padding-bottom: 10px;
            box-shadow: none !important;
            outline: none !important;
        }

    }

    .form-group{
        textarea{
            resize: none;
            box-shadow: none !important;
            outline: none !important;
        }
    }
}

@media (max-width: 788px) {

    .form-wrapper{
        width:100%;
    }
    .map-wrapper{
        width:100%;
        height:250px;
        // padding: 1rem;
    }
    
  }

  @media (min-width: 789px) {
    .map-wrapper{
        width:50%;
    }
    .form-wrapper{
        width:50%;
    }
    .info-wrapper{
    }
  }



