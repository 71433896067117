@import "../../sass/custom.scss";

@mixin dataAdd($color) {
  font-weight: 600;
}

[data-add-btn="true"] {
  @include dataAdd($primary);
}

[data-add-btn="false"] {
  @include dataAdd($grey-500);
}


@media (min-width: $sm) {

}

@media (max-width: 619px) {

  .menu-translate {
    top: 9px;
    right: 15px;
  }
}

[data-main-wrapper = 'false']{
    left:0%;
}

[data-main-wrapper = 'true']{
    left:-100%;
}


.nav-btn-active{
  background-color: #f7d100;

}

.nav-text-wrapper{
  position:absolute;
  border-radius: .2rem;
  top:0px;
  left:0px;
  background-color: #f7d100;
  // width:100%;
  height:100%;
  z-index: -1;
  box-shadow: inset 0 0 10px #f8a100;
}

.form-select-arrow {
  color: red;
}

.select-arrow {
  color: red;
}
