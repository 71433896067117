
.btn-details-inner{
    position: absolute;
    width: 100%,;
    height: 100%;
    left: 0px;
    top: 0px;
    clip-path: polygon(0% 100%, 0% 0%, 20% 0%, 25% 50%, 30% 100%);
    z-index: 1;
    transition: all .5s ease;
}

.btn-to-details:hover {
    .btn-details-inner {
        clip-path: polygon(0% 100%, 0% 0%, 70% 0%, 78% 50%, 70% 100%);
    }
}