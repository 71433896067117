.header-info {
  transition: all 0.5s ease;
  .info-icon {
    transition: all 0.3s ease;
  }
}

.header-info:hover {
  background-color: #2d2e36;
  .info-icon {
    transform: scale(1.2);
  }
}

@media (min-width: 620px) {
  .logo-header {
    width: 160px;
    padding-top: 10px;
    // padding-bottom: 8px;
  }
}

@media (max-width: 619px) {
  .logo-header {
    width: 120px;
    padding-top: 6px;
    padding-bottom: 5px;
  }
}
