.details-article-wrapper {
  min-height: 100vh;
  z-index: 1;
  margin-top: 60px;

  .details-article {
    display: flex;

    gap: 1.5rem;

    .details-article-left {
      width: 60%;
    }

    .details-article-right {
      width: 40%;
      background-color: #fff;
      padding: 1.5rem;

      .article-body {
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .table {
    border-collapse: separate;
    border-spacing: 0px;
    background-color: #fff;
    font-size: 0.8rem;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);

    th {
      padding: 15px 20px 15px 20px;
    }

    td {
      padding: 10px 20px 10px 20px;
    }

    tr :not(:first-child) {
      border: none;
    }

    tr :first-child {
      font-weight: 500;
      border: none;
    }

    tr:nth-child(even) {
      background-color: rgba($color: #000000, $alpha: 0.03);
    }
  }

  .tables-wrapper {
    display: flex;
    gap: 1.5rem;
    align-items: baseline;

    .table-first {
      width: 60%;
    }
    .table-second {
      width: 40%;
    }
  }

  .swiper {
    width: 100%;
    padding: 0px 0px 30px 0px;
  }

  .swiper-slide {
    font-size: 18px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .swiper-slide img {
    width: 100%;
    border-radius: 0.3rem;
  }
  .swiper-pagination-bullet-active {
    background-color: #f7d100 !important;
    width: 30px !important;
  }

  .swiper-pagination-bullet-inactive {
    transition: all 0.25s ease;
  }
  .swiper-pagination-bullet {
    transition: all 0.25s ease;
    border-radius: 2px !important;
  }
  .swiper-pagination {
    bottom: 0px !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #f7d100 !important;
  }
}

@media (max-width: 789px) {
  .details-article-wrapper {
    margin-top: 130px;

    .details-article {
      flex-direction: column;
      gap: 1.5rem;

      .details-article-left {
        width: 100%;
      }

      .details-article-right {
        width: 100%;
        padding: 1rem;
        .article-body {
          display: flex;
          flex-direction: column;
          margin-top: 1.5rem;
          align-items: baseline;
          width: 100%;

          .proces-text {
            width: 100%;
          }

          .article-price {
            width: 100%;
          }
        }
      }
    }

    .table {
      th {
        padding: 15px 20px 15px 20px;
      }

      td {
        padding: 10px 20px 10px 20px;
      }
    }

    .tables-wrapper {
      flex-direction: column;
      gap: 1.5rem;

      .table-first {
        width: 100%;
      }
      .table-second {
        width: 100%;
      }
    }
  }
}

@media (max-width: 619px) {
  .details-article-wrapper {
    margin-top: 55px;
  }
}

@media (max-width: 479px) {
  .details-article-wrapper {
    .table {
      font-size: 0.7rem;

      th {
        padding: 12px 12px 12px 12px;
      }

      td {
        padding: 8px 12px 8px 12px;
      }
    }
  }
}

.swiper {
  width: 100%;
  padding: 0px 0px 0px 0px;
}

.swiper-slide {
  font-size: 18px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.swiper-slide img {
  width: 100%;
  border-radius: 0.3rem;
}
.swiper-pagination-bullet-active {
  background-color: #f7d100 !important;
  width: 30px !important;
}

.swiper-pagination-bullet-inactive {
  transition: all 0.25s ease;
}
.swiper-pagination-bullet {
  transition: all 0.25s ease;
  border-radius: 2px !important;
}

.swiper-pagination {
  bottom: 0px !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #f7d100 !important;
}

.bread-btn-first {
  background-color: transparent;
  border: none;
}
