circle {
  fill: transparent;
  //
}

@media (min-width: 620px) {
  .yellow-part {
    height: 130px;
  }

  .number-icon {
    top: 30px;
    left: 30px;
  }
}

@media (max-width: 619px) {
  .yellow-part {
    height: 60px;
  }

  .number-icon {
    top: 20px;
    left: 20px;
  }
}
