.underline {
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 30px;
  width: 30px;
  border-top-right-radius: 7px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}

.filter-bg-colors {
  left: 0;
  top: 0;
  z-index: 0;
}
.accordion-header {
  .filter-bg-colors1 {
    clip-path: polygon(0% 0%, 200px 0%, 140px 100%, 0% 100%);
    transition: all 1s ease;
  }
  .filter-bg-colors2 {
    clip-path: polygon(0% 0%, 180px 0%, 90px 100%, 0% 100%);
    transition: all 1s ease;
  }

  .filter-text {
    font-weight: 500;
    opacity: 0;
    transition: all 0.6s ease;
  }
}

.accordion-header:hover {
  .filter-bg-colors1 {
    clip-path: polygon(0% 0%, 170px 0%, 230px 100%, 0% 100%);
  }
  .filter-bg-colors2 {
    clip-path: polygon(0% 0%, 200px 0%, 110px 100%, 0% 100%);
  }

  .filter-text {
    font-weight: 500;
    opacity: 1;
  }
}

.accordion-header:active {
  .filter-bg-colors1 {
    clip-path: polygon(0% 0%, 170px 0%, 230px 100%, 0% 100%);
  }
  .filter-bg-colors2 {
    clip-path: polygon(0% 0%, 200px 0%, 110px 100%, 0% 100%);
  }

  .filter-text {
    font-weight: 500;
    opacity: 1;
  }
}

.form-check-input {
  box-shadow: none !important;
}

.details-button {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.container-fluid-02 {
  ul {
    display: grid;
    gap: 1.5rem;
  }
}

.filter-wrapper {
  transition: all 1s ease;
}

[data-filter-wrapper='true'] {
  height: 100%;
}

[data-filter-wrapper='false'] {
  height: 0px;
}

$thumb-size: 16px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.input-wrapper {
  width: calc(100% + #{$thumb-size});
  margin: 0 calc(#{$thumb-size} / -2);
  height: $thumb-size;
}

.control-wrapper {
  height: $thumb-size;
}

.input {
  appearance: none;
  z-index: 3;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
}

.rail {
  transform: translateY(-50%);
  height: 6px;
}

.control {
  width: $thumb-size;
  height: $thumb-size;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate(0, -50%);
  z-index: 2;
}
