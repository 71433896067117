.hero-swiper-wrapper {
  .swiper {
    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20px !important;
      padding: 10px;
      // background-color: rgba(255,255,255,1);
      width: auto;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .swiper-pagination-bullet {
      background-color: rgb(255, 255, 255);
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
}

.hero-text-image-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  .hero-text-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    .hero-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      .hero-text-p {
        padding-top: 4rem;
      }
      .hero-text-h1 {
        font-size: 3rem;
      }
    }
  }

  .hero-image-wrapper {
    width: 65%;

    .hero-image {
      width: 100vw;
      height: 100vh;

      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;

      clip-path: polygon(150px 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
}
@media (max-width: 600px) {
  .swiper-button-prev {
    display: none;
  }

  .swiper-button-next {
    display: none;
  }
}

@media (max-width: 1350px) {
  .hero-text-image-wrapper {
    .hero-text-wrapper {
      .hero-text {
        .hero-text-h1 {
          line-height: 2.6rem;
          font-size: 2.5rem;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .hero-text-image-wrapper {
    flex-direction: column-reverse;

    .hero-text-wrapper {
      padding-bottom: 3rem;
      width: 100%;
      justify-content: center;
      .hero-text {
        width: 70%;
      }
    }

    .hero-image-wrapper {
      width: 100%;
      position: relative;
      .hero-image {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        width: 100vw;
        height: 60vh;
      }
    }
  }

  .hero-swiper-wrapper {
    .swiper {
      .swiper-pagination {
        bottom: 0px !important;
        padding: 10px;
      }
      .swiper-pagination-bullet {
        background-color: rgb(0, 0, 0);
        opacity: 0.2;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 620px) {
  .hero-text-image-wrapper {
    .hero-text-wrapper {
      .hero-text {
        padding-left: 2rem;
        padding-right: 2rem;
        width: 100%;
        .hero-text-p {
          padding-top: 2rem;
        }
      }
    }

    .hero-image-wrapper {
      .hero-image {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        width: 100vw;
        height: 50vh;
      }
    }
  }

  .hero-swiper-wrapper {
    .swiper {
      .swiper-pagination {
        bottom: 0px !important;
        padding: 10px;
      }
      .swiper-pagination-bullet {
        background-color: rgb(0, 0, 0);
        opacity: 0.2;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 360px) {
  .hero-text-image-wrapper {
    .hero-text-wrapper {
      padding-bottom: 2.5rem;
      .hero-text {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
